import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const ContactPerson = (props) => {
  return (
    <div className={props.className + " grid content-between grid-cols-1 max-w-md lg:max-w-none p-4 mx-auto md:p-6 lg:py-20"}>
      <div>
        <h3 className="h1 lg:text-2xl">{props.headline}</h3>
        <div><p className="mb-6 lg:text-xl">{props.text}</p></div>
      </div>
      <div className="flex items-center gap-6">
        <div className="w-1/5 ml-auto md:m-0 xl:w-1/4">
          <GatsbyImage
            image={props.contactImage}
            className="w-full"
            alt={props.contactImageAltAttribute} />
        </div>
        <div className="mr-auto md:m-0">
          <h4 className="text-xl font-bold">{props.contactName}</h4>
          <h5 className="mb-4">{props.contactPosition}</h5>
          <a href={props.contactBtnLinkUrl} className="underline btn btn--tertiary">{props.contactBtnLinkText}</a>
        </div>
      </div>
    </div>
  );
};

export default ContactPerson;
