import React from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby-link"

function encode(data) {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}

function ContactFormComponent() {
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <section
      className="relative"
    >
      <form
        name="Contact"
        method="post"
        action="/thank-you"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        className="contact-form"
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="Contact" />
        <p hidden>
          <label>
            Don’t fill this out:
            <input name="bot-field" onChange={handleChange} />
          </label>
        </p>

        <div className="grid gap-2 md:grid-cols-2 md:gap-4">
          <div>
            <label htmlFor="first-name">Name</label>
            <input
              placeholder="Hugo"
              id="first-name"
              type="text"
              onChange={handleChange}
              name="firstName"
              required
            />
          </div>
          <div className="md:row-start-2">
            <label htmlFor="email">E-mail</label>
            <input
              placeholder="hugo@gmail.com"
              id="email"
              type="email"
              onChange={handleChange}
              name="email"
              required
            />
          </div>
          <div className="md:row-start-2">
            <label htmlFor="phone">Phone (optional)</label>
            <input
              placeholder="+1 3193 381 3913"
              id="phone"
              type="tel"
              onChange={handleChange}
              name="phone"              
            />
          </div>
          <div className="md:col-span-2 md:row-start-3">
            <label htmlFor="message">Message:</label>
            <textarea
              className="form-textarea"
              id="message"
              placeholder="Please reach out to me "
              rows="3"
              onChange={handleChange}
              name="message"
              required
            />
          </div>
        </div>

        <div className="flex md:col-span-2">
          <label className="flex items-center text-sm md:text-base">
            <input type="checkbox" className="form-checkbox" required />
            <span className="ml-3 text-gray-400">
              I agree that my details from the contact form are collected and processed in order to answer my enquiry. Detailed information on the handling of user data can be found in our&nbsp;
              <Link to="/privacy-policy" title="Our privacy policy" className="underline">
                privacy policy
              </Link>&nbsp;
              .
            </span>
          </label>
        </div>

        <button className="block px-6 py-2 my-4 ml-auto font-bold text-white rounded bg-secondary-font">
          Send message
        </button>
      </form>
    </section>
  )
}

export default ContactFormComponent
