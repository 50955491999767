import React from "react";
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

// import useCalendlyInline from "../hooks/useCalendlyInline";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Nav from "../components/Nav"
import ContactPerson from "../components/contactPerson";

export const query = graphql`query ContactPageQuery {
  happyCustomer: file(relativePath: {eq: "woman-smiling-behind-counter.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 740, layout: CONSTRAINED)
    }
  }
  rentwareOffice: file(relativePath: {eq: "rentware-office-team.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 740, layout: CONSTRAINED)
    }
  }
  goeranSchwind: file(relativePath: {eq: "goeran-schwind-rentware-manager.png"}) {
    childImageSharp {
      gatsbyImageData(width: 250, layout: CONSTRAINED)
    }
  }
  techTeam: file(relativePath: {eq: "rentware-tech-team.png"}) {
    childImageSharp {
      gatsbyImageData(width: 250, layout: CONSTRAINED)
    }
  }
}
`

const ContactPage = ({ data }) => {
  // useCalendlyInline();
  return (
    <Layout>
      <Seo
        seo={{
          metaTitle: "Contact",
          metaDescription: "It looks like you got to the contact",
        }}
      />
      <Nav />
      <div className="grid mx-auto md:grid-cols-2 lg:max-w-screen-xl xl-max-w-screen-2xl lg:mt-12">
        <div className="max-w-2xl p-4 mb-4 md:p-6 md:my-6 lg:mt-12 lg:mb-20">
          <h1>Questions about our solutions?</h1>
          <p>One of our product people will discuss any question you have about our product.</p>
          <div className="mt-2 font-bold">Product team</div>
          <a href="tel:+49309203854012" className="my-2 mr-2 btn btn--tertiary">+49 30 9203854012</a>
          <a href="mailto:sales@rentware.com" className="my-2 underline btn btn--tertiary">sales@rentware.com</a>
        </div>
        <div className="w-full max-w-2xl place-self-end">
        <GatsbyImage
          image={data.happyCustomer.childImageSharp.gatsbyImageData}
          className="w-full"
          alt="TODO" />
        </div>
      </div>
      {/* <div className="py-6 md:-mt-6 bg-secondary-bg">
        <div className="grid items-center mx-auto md:grid-cols-2 lg:max-w-screen-xl xl-max-w-screen-2xl">
          <div className="max-w-2xl p-4 md:p-6 md:order-2">
            <h2 className="h1 lg:text-2xl">Schedule a call with us to call you back.</h2>
            <div>
              <p className="lg:text-xl">One of our product people will reach out to you. Of course you can also reach out to us via e-mail or send us a chat message.</p>
            </div>
          </div>
          <div className="min-w-80 h-calendly-mobile md:h-calendly-desktop max-w-[650px]">
            {/* Calendly Inline-Widget Beginn */}
            {/* <div id="calendly-inline-widget" className="h-full"></div> */}
            {/* Calendly Inline-Widget End */}
          {/* </div>
        </div>
      </div> */}
      <div className="grid gap-6 mx-auto md:items-center sm:grid-cols-2 lg:grid-cols-3 lg:gap-16 lg:max-w-screen-xl xl-max-w-screen-2xl">
        <ContactPerson 
          headline = 'Business opportunities'
          text = 'You can also reach out to us anytime to discuss your business opportunity with Rentware.'
          contactImage = {data.goeranSchwind.childImageSharp.gatsbyImageData}
          contactImageAltAttribute = 'Business portrait of Göran Schwind'
          contactName = 'Göran Schwind'
          contactPosition = 'General manager'
          contactBtnLinkUrl = 'mailto:sales@rentware.com'
          contactBtnLinkText = 'sales@rentware.com'
        />
        <div className="order-3 w-full mx-auto lg:order-2 sm:col-span-2 sm:max-w-md lg:max-w-none lg:col-span-1">
          <GatsbyImage
            image={data.rentwareOffice.childImageSharp.gatsbyImageData}
            className="w-full"
            alt="TODO" />
        </div>
        <ContactPerson
          className = 'order-2 lg:order-3'
          headline = 'Technical support'
          text = 'Do you want to work with our API or have technical questions? Let us know!'
          contactImage = {data.techTeam.childImageSharp.gatsbyImageData}
          contactImageAltAttribute = 'Business portrait of Göran Schwind'
          contactName = 'Tech team'
          contactPosition = 'API and technologies'
          contactBtnLinkUrl = 'mailto:techteam@rentware.com'
          contactBtnLinkText = 'techteam@rentware.com'
        />
      </div>
    </Layout>
  );
}

export default ContactPage;
