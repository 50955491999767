import React from "react";
import PropTypes from "prop-types";

import Footer from "./footer";
import TheEnd from "./theEnd";
export default function Layout({ children }) {
	return (
		<>
			<>{children}</>
			<Footer />
			<TheEnd />
		</>
	);
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};
