import { Link } from "gatsby"
import React from "react"
import ContactFormComponent from "../components/ContactForm"

const Footer = () => (
  <footer className="border-t border-gray-400">
    <div className="items-center mx-auto divide-y divide-gray-400 max-w-screen-2xl md:grid md:grid-cols-2 md:divide-y-0">
      <div className="border-gray-400 md:border-r">
        <div className="max-w-xl p-6 mx-auto mt-4 md:py-12 lg:py-24">
          <ContactFormComponent />
        </div>
      </div>
      <div>
        <div className="max-w-xl p-6 mx-auto md:py-12 lg:py-24 lg:text-lg">
          <h3 className="text-xl h1 lg:mb-8"><Link to="/contact">Contact</Link></h3>
          <div className="flex flex-wrap gap-6 md:gap-12 md:flex-row md:flex-no-wrap">
            <div>
              <address className="not-italic">
                <div className="mb-2">
                  E-mail: <a href="mailto:sales@rentware.com">sales@rentware.com</a><br/>
                  Phone: <a href="tel:+49309203854012">+49 30 9203854012</a>
                </div>
                <div>
                  Ernst-Augustin-Str. 12<br/>
                  12489 Berlin<br/>
                  Germany
                </div>
              </address>
            </div>
            <div>
              <nav>
                <ul>
                  <li>
                    <Link to="/imprint">Imprint</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy policy</Link>
                  </li>
                  {/* <li>
                    <a href="">Cookie settings</a>
                  </li> */}
                  {/* We don't have terms and conditions (2020-12-29)
                  <li>
                    <Link to="/terms-and-conditions">Terms and conditions</Link>
                  </li> */}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
